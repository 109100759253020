import { useState } from 'react';

export default function ButtonGroup({ buttons, className }) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleClick = (btn, i) => {
    setSelectedIndex(i);
    btn.onClick && typeof btn.onClick === 'function' && btn.onClick();
  };

  return (
    <div className={`rounded-md border-1 d-inline-flex ${className}`}>
      {buttons.map((btn, i) => {
        return (
          <button
            key={i}
            onClick={() => handleClick(btn, i)}
            className={`flex-1 p-2 
              ${i < buttons.length ? 'border-r-1' : ''}
              ${i === selectedIndex ? 'text-gray-350' : 'text-black'}
            `}
          >
            {btn.label}
          </button>
        );
      })}
    </div>
  );
}
