import ProfileBar from '../Components/ProfileBar';
import Tabs from 'components/Tab';
import { useParams, useLocation } from 'react-router-dom';
import { useMatchAnalytics } from '../queries/profileHooks';
import Loader from 'components/Loader';
import MatchSummary from './components/MatchSummary';
import SummaryStats from './SummaryStats';
import ReceiveAnalysis from './ReceiveAnalysis';
import ServiceAnalysis from './ServiceAnalysis';
import SpeedAnalysis from './SpeedAnalysis';
import RallyAnalysis from './RallyAnalysis';
// import BallHeatMap from './BallHeatMap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import PoweredByStupa from './components/PoweredByStupa';

export default function MatchAnalytics() {
  const { match_id: matchId } = useParams();
  const { state: matchDetails } = useLocation();
  const matchAnalytics = useMatchAnalytics({ matchId });

  return (
    <div className="flex-1 bg-white md:bg-gray-100">
      <div className="d-flex justify-content-between bg-white">
        <ProfileBar head="Match Analytics" />
        <div className="hidden md:block mr-8">
          {matchDetails && <MatchSummary data={matchDetails} />}
        </div>
      </div>

      <div className="md:hidden mx-4 rounded bg-gray-100 ">
        {matchDetails && <MatchSummary data={matchDetails} />}
      </div>

      <div className="justify-center my-4 mx-0 md:mx-4 xl:mx-9 font-roboto">
        <Tabs
          color="text-gray-750"
          border="border-gray-750"
          tabList={[
            {
              name: 'Summary Stats',
              component: <TabPanel data={matchAnalytics} Component={SummaryStats} />,
            },
            {
              name: 'Service Analysis',
              component: <TabPanel data={matchAnalytics} Component={ServiceAnalysis} />,
            },
            {
              name: 'Receive Analysis',
              component: <TabPanel data={matchAnalytics} Component={ReceiveAnalysis} />,
            },
            {
              name: 'Speed Analysis - Contact View',
              component: <TabPanel data={matchAnalytics} Component={SpeedAnalysis} />,
            },
            {
              name: 'Rally Analysis/Point Conversion',
              component: <TabPanel data={matchAnalytics} Component={RallyAnalysis} />,
            },
            // {
            //   name: 'Ball Heat Map',
            //   component: <TabPanel data={matchAnalytics} Component={BallHeatMap} />,
            // },
          ]}
        />
      </div>
      <div className="d-flex justify-content-center justify-content-md-end mx-4 md:mx-9 mb-4">
        <PoweredByStupa />
      </div>
    </div>
  );
}

const TabPanel = ({ data, Component }) => {
  return (
    <div className="p-4 bg-white md:p-0 md:bg-gray-100 h-mi">
      <div className="bg-white rounded border-1 font-roboto md:my-6 md:mx-0 p-4">
        {data.isLoading ? (
          <Loader />
        ) : data.isSuccess && data.data ? (
          <Component data={data.data} />
        ) : (
          <EmptyState />
        )}
      </div>
    </div>
  );
};

const EmptyState = () => {
  return (
    <div className="h-96 w-full d-flex flex-col align-items-center justify-content-center">
      <FontAwesomeIcon icon={faChartBar} className="text-gray-300 h-40" />
      <p className="text-4xl font-mono font-bold">Oops!</p>
      <p className="text-lg">No Data Found</p>
    </div>
  );
};
