export default function TotalAnalysis({ data, title }) {
  return (
    <>
      <div className="font-bold text-lg mb-2">{title}</div>
      <div className="d-flex flex-col md:flex-col-reverse">
        <div className="d-flex my-4 text-sm gap-4">
          <div className="d-flex align-items-center">
            <div className="h-6 w-6 rounded-full mr-2 bg-destruction"></div>
            <span>Lost</span>
          </div>
          <div className="d-flex align-items-center">
            <div className="h-6 w-6 rounded-full mr-2 bg-success"></div>
            <span>Won</span>
          </div>
        </div>
        <div className="rounded bg-gray-100 my-4 p-6 grid grid-rows md:grid-cols-3 gap-10 md:gap-20">
          <Analysis title="Long" data={data.LongSort} />
          <Analysis title="Half Long" data={data.MediumSort} />
          <Analysis title="Short" data={data.ShortSort} />
        </div>
      </div>
    </>
  );
}

const Analysis = ({ title, data }) => {
  return (
    <div>
      <div className="font-bold text-center mb-4">{title}</div>
      {data.map((d, i) => (
        <div key={i} className="text-sm my-4">
          <div className="mb-4">{d.PlayerName}</div>
          <AnalysisBar lost={d.Lost} won={d.Won} />
        </div>
      ))}
    </div>
  );
};

const AnalysisBar = ({ lost, won }) => {
  if (!lost && !won) {
    return (
      <div className="d-flex flex-row text-white text-center">
        <div style={{ width: `50%` }} className="bg-gray-400 border-r-1">
          -
        </div>
        <div style={{ width: `50%` }} className="bg-gray-400">
          -
        </div>
      </div>
    );
  }
  const lostPercentage = (lost * 100) / (lost + won);
  const wonPercentage = 100 - lostPercentage;

  return (
    <div className="d-flex flex-row text-white text-center">
      <div style={{ width: `${lostPercentage}%` }} className="bg-destruction">
        {lost || '-'}
      </div>
      <div style={{ width: `${wonPercentage}%` }} className="bg-success">
        {won || '-'}
      </div>
    </div>
  );
};
