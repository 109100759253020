import { useUserProfile } from 'profiles/queries/profileHooks';

export default function MatchSummary({ data }) {
  const userProfileQuery = useUserProfile();

  return (
    <div className="h-full d-flex flex-col-reverse md:flex-row justify-content-center align-items-center py-2">
      <div className="w-full md:w-auto d-flex justify-content-between md:flex-col md:align-items-end px-2">
        <div>{data.round_name}</div>
        <div className="bg-blue-iris rounded d-inline px-2 text-white text-center">
          {data.score || '-'}
        </div>
      </div>

      <div className="hidden md:block h-full border-r-1 mx-2"></div>
      <div className="md:hidden w-full border-b-1 my-2"></div>

      <div className="h-full w-full md:w-auto d-flex flex-row justify-content-between md:align-items-end align-items-center px-2">
        <div>
          <div>{userProfileQuery.data.message.full_name}</div>
          <div
            className={`rounded d-inline px-2 py-1 text-xs ${
              data.result === 'Qualified' ? 'bg-green-700 text-white' : 'bg-gray-150 text-gray-450'
            }`}
          >
            {data.result}
          </div>
          <div className="text-sm text-tertiary-100">
            {userProfileQuery.data.message.school_name}
          </div>
        </div>

        <div className="d-flex align-items-center mx-3 text-gray-500 text-sm">VS</div>

        <div>
          <div>{data.opponent}</div>
          <div
            className={`rounded d-inline px-2 py-1 text-xs ${
              data.result === 'Qualified' ? 'bg-green-700 text-white' : 'bg-gray-150 text-gray-450'
            }`}
          >
            {data.result}
          </div>
          <div className="text-sm text-tertiary-100">{data.opponent_school}</div>
        </div>
      </div>
    </div>
  );
}
