import Tabs from 'components/Tab';
import SpeedAnalysisGraph from './components/SpeedAnalysisGraph';

export default function SpeedAnalysis({ data }) {
  const {
    AIData: {
      AIResponse: { SpeedGraph },
    },
  } = data;

  const getStepData = (stepNumber) => {
    return SpeedGraph.find((s) => s.GameNo === stepNumber);
  };

  return (
    <>
      <Tabs
        color="text-gray-750"
        border="border-gray-750"
        tabList={SpeedGraph.map((graph, index) => ({
          name: `${index === 0 ? 'Match' : `Set ${index}`}`,
          component: <SpeedAnalysisGraph data={getStepData(index)} />,
        }))}
      />
    </>
  );
}
