import { ResponsiveLine } from '@nivo/line';
import { useEffect, useState } from 'react';
import ButtonGroup from 'components/ButtonGroup';
import PlayerLegends from './PlayerLegends';

const FILTER_TYPE = {
  WON: 'WinningSpeeds',
  TOTAL: 'TotalSpeeds',
};

const COLORS = ['rgb(55, 48, 163)', 'rgb(165, 180, 252)'];

const formatData = (data) => {
  const sets = [...new Set(data.map((d) => d.ShotNo))];
  const players = [...new Set(data.map((d) => d.PlayedBy))];

  return players.map((player, i) => {
    const playerData = data.filter((d) => d.PlayedBy === player);
    return {
      gameNo: data[0].GameNo,
      id: player,
      color: COLORS[i],
      data: sets
        .map((set) =>
          playerData
            .filter((pd) => pd.PlayedBy === player && pd.ShotNo === set)
            .map((pd) => ({
              x: pd.ShotNo,
              y: pd.AverageSpeed,
            }))
        )
        .flat(),
    };
  });
};

export default function SpeedAnalysisGraph({ data }) {
  const [graphData, setGraphData] = useState([]);
  const [filter, setFilter] = useState(FILTER_TYPE.TOTAL);

  useEffect(() => {
    setGraphData(formatData(data[filter]));
  }, [data, filter]);

  return (
    <div className="h-auto">
      <div className="py-4 d-flex justify-content-between align-items-center">
        <PlayerLegends players={graphData.map((d) => d.id)} />
        <ButtonGroup
          buttons={[
            {
              label: 'Total',
              onClick: () => setFilter(FILTER_TYPE.TOTAL),
            },
            {
              label: 'Won',
              onClick: () => setFilter(FILTER_TYPE.WON),
            },
          ]}
        />
      </div>

      <div className="h-96">
        {graphData && (
          <ResponsiveLine
            data={graphData}
            margin={{ top: 20, right: 20, bottom: 50, left: 50 }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: 'bottom',
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Ball Contacts',
              legendOffset: 36,
              legendPosition: 'middle',
            }}
            axisLeft={{
              orient: 'left',
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Speed (KMPH)',
              legendOffset: -40,
              legendPosition: 'middle',
            }}
            enableGridX={false}
            colors={{ datum: 'color' }}
            pointColor={{ from: 'color', modifiers: [] }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            isInteractive={false}
            useMesh={true}
            legends={[]}
            enableCrosshair={false}
          />
        )}
      </div>
    </div>
  );
}
