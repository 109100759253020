export default function PlayerLegends({ players }) {
  return (
    <div className="d-flex flex-col md:flex-row my-4 text-sm gap-4">
      <div className="d-flex align-items-center">
        <div className="h-6 w-6 rounded-full mr-2 bg-indigo-800"></div>
        <span>{players[0]}</span>
      </div>
      <div className="d-flex align-items-center">
        <div className="h-6 w-6 rounded-full mr-2 bg-indigo-300"></div>
        <span>{players[1]}</span>
      </div>
    </div>
  );
}
