import { useEffect, useMemo, useState } from 'react';
import PlayerLegends from './components/PlayerLegends';
import ButtonGroup from 'components/ButtonGroup';
import HorizontalSlider from 'components/HorizontalSlider';

const FILTER_TYPE = {
  LOST: 'lost',
  WON: 'won',
};

export default function RallyAnalysis({ data }) {
  const {
    AIData: {
      AIResponse: { Rallies },
    },
    AnalysisData: { PlayerA, PlayerB },
  } = data;

  const [rallies, setRallies] = useState([...Rallies]);
  const [filter, setFilter] = useState(FILTER_TYPE.LOST);

  useEffect(() => {
    setRallies(
      filter === FILTER_TYPE.LOST
        ? [...Rallies.filter((r) => r.Rally !== 'null')]
        : [...Rallies.filter((r) => r.Rally !== 'null').map((r) => ({ A: r.B, B: r.A }))]
    );
  }, [filter]);

  const maxRally = useMemo(() => {
    return Math.max(...rallies.flatMap((d) => [d.A, d.B]));
  }, [rallies]);

  return (
    <div className="h-auto">
      <div className="py-4 d-flex justify-content-between align-items-center">
        <PlayerLegends players={[PlayerA.Name, PlayerB.Name]} />
        <ButtonGroup
          buttons={[
            {
              label: 'Lost',
              onClick: () => setFilter(FILTER_TYPE.LOST),
            },
            {
              label: 'Won',
              onClick: () => setFilter(FILTER_TYPE.WON),
            },
          ]}
        />
      </div>

      <div className="h-96">
        <Rally label="Short (1-4)" data={rallies[0]} maxRally={maxRally} />
        <Rally label="Medium (5-8)" data={rallies[1]} maxRally={maxRally} />
        <Rally label="Long (Above 9)" data={rallies[2]} maxRally={maxRally} />
      </div>
    </div>
  );
}

const Rally = ({ label, data, maxRally }) => {
  return (
    <div className="w-full grid grid-cols-8 gap-2 border-b-1 py-8">
      <div className="col-span-3 md:col-span-1">{label}</div>
      <div className="col-span-5 md:col-span-7 grid grid-rows-2 gap-8 px-2">
        <HorizontalSlider color="bg-indigo-800" baseValue={maxRally} value={data.A} />
        <HorizontalSlider color="bg-indigo-300" baseValue={maxRally} value={data.B} />
      </div>
    </div>
  );
};
