import PlayerStats from './components/PlayerStats';
import TotalAnalysis from './components/TotalAnalysis';

export default function ServiceAnalysis({ data }) {
  const {
    AIData: {
      AIResponse: {
        ServiceAnalysis: { PlayerA, PlayerB, PionWonOnShots },
      },
    },
  } = data;

  return (
    <>
      <div className="grid grid-cols-2 gap-4 mb-4 border-b-1">
        <PlayerStats data={PlayerA} label="Services" showTotal />
        <PlayerStats data={PlayerB} label="Services" showTotal />
      </div>

      <TotalAnalysis data={PionWonOnShots} title="Service Analysis" />
    </>
  );
}
