import { ReactComponent as StupaLogo } from 'assets/img/logo/stupa_logo_dark.svg';

export default function PoweredByStupa() {
  return (
    <div className="text-tertiary-100 d-flex flex-column align-items-center font-roboto text-sm">
      <div>Powered By</div>
      <StupaLogo width={70} />
    </div>
  );
}
