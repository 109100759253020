export default function HorizontalSlider({ color, baseValue, value }) {
  return (
    <div className="relative bg-gray-200 w-full h-2 rounded d-flex align-items-center">
      <div
        className={`absolute h-full rounded d-flex align-items-center justify-content-end ${color}`}
        style={{ width: `${(value * 100) / baseValue}%`, transition: 'width .5s' }}
      >
        <div
          className={`absolute h-5 w-5 rounded-full text-xxs text-white d-flex justify-content-center align-items-center -right-2 ${color}`}
        >
          {value}
        </div>
      </div>
    </div>
  );
}
